// #logo {
//     // background: url('img/book-open-solid.svg');
//     background: none;
//     margin: 10px 0;
//
//     a {
//         position: relative;
//         display: inline-block;
//         height: auto !important;
//         padding: 0;
//         width: 250px;
//         padding-left: 55px;
//
//         &:before {
//             content: '';
//             display: inline-block;
//             // margin-left: -40px;
//             width: 40px;
//             height: 40px;
//             // outline: 1px solid #ccc;
//             background: url('../img/book-open-r.svg') no-repeat center;
//             // background: url('https://bibliothek.apabiz.de/static/book-reader.svg') no-repeat center;
//             background-size: contain;
//             // https://raw.githubusercontent.com/FortAwesome/Font-Awesome/master/svgs/solid/book-open.svg
//             position: absolute;
//             top: 5px;
//             left: 0;
//         }
//     }
// }
//
// h1#logo {
//     font-family: $header-font;
//     font-weight: 500;
//     width: 380px;
//     margin: 1.35em .3em .75em 1em;
// }
//
// #logo,
// #logo a {
//     color: $dark !important;
// }
//
// #logo a {
//     width: 350px;
// }
//
// #cataloguing_additem_newitem {
//     #subfield9520,
//     #subfield9523,
//     #subfield9524,
//     #subfield9525,
//     #subfield9527,
//     #subfield9528,
//     #subfield952e,
//     #subfield952f,
//     #subfield952g,
//     #subfield952h,
//     #subfield952j,
//     #subfield952p,
//     #subfield952t,
//     #subfield952u,
//     #subfield952v,
//     #subfield952w,
//     #subfield952x {
//         display: none;
//     }
// }
