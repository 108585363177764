// #ca003a
// #ae1d39
// #932638
// #782b37
// #5d2c36
// #412b35
// #222834

// https://mycolor.space/?hex=%23222834&sub=1

// colours
// $dark: #222834;
// $dark: #344a6d;
$dark: #2f4858;
$red:  #ae1d39;
$blue: #37669b;
$blue: #ae1d39;

$light: #eceeef;
$light2: #d9dde0;

$text: #222834;
// $light: #d9dde0;
// $light: #f7eaed;
//
// $red:			   #cc071e;
// $blue:			   #021948;
// // $red-light:	   	   lighten($red, 20%);
// // $gray:			   #333;
//
// $text:			   $blue;
// $background:       #fff;
// // $background-nav:   $blue;
// $background-nav:   transparentize($blue, 0.2);
// $contrast:         $red;
// $text-hover:       $contrast;
//
// // font size
$fs-base:            16px;
// // $fs-base-mob:        0.85;
// $fs-large:           1.25;
// // $fs-large-mob:       1.0;
// $fs-small:           0.9;
// // $fs-small-mob:       0.75;
// $fs-map:             0.8;
//
// $fs-h1:              2.0;
// // $fs-h1-mob:          1.25;
// $fs-h2:              1.35;
// // $fs-h2-mob:          0.95;
// $fs-h3:              1.4;
// // $fs-h3-mob:          1,2;
// $fs-blockquote:      1.1;
// // $fs-blockquote-mob:  1.15;
// $fs-nav:             $fs-base;
//
// $lh-base:            1.5em;
// $lh-h1:              1.5em;
// $lh-h2:              1.4em;
// $lh-h3:              1.4em;
// $lh-blockquote:      1.4em;
// $lh-large:           1.5em;
// $lh-small:           1.55em;
// $lh-nav:             1.4em;
//
// // other vars
$base-font: 'Source Sans Pro', 'Helvetica', 'Arial', sans-serif;
$header-font: 'Roboto Slab', serif;
//
// $site-width: 2000px;
// $content-width: 1200px;
